import { PageProps } from 'gatsby'
import React, { useEffect } from 'react'
import { useGetCourseDetails } from '../../../../../hooks/useGetCourseDetails'

const AssetReporting: React.FC<PageProps> = ({ params: { id: course_id, asset_id } }) => {
	const { ShowDetails } = useGetCourseDetails(course_id)

	useEffect(() => {
		if (asset_id) console.log(asset_id)
	}, [asset_id])
	return (
		<>
			{ShowDetails}
			<hr />
			{asset_id}
		</>
	)
}

export default AssetReporting
